<template>
  <Widget
    title="Ending Holidays"
    icon="track_changes"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <template slot="filters">
      <form-date
        v-model="filters.date"
        label="Returning Date"
        class="w-100 d-inline-block"
      />
      <md-button
        class="md-info md-sm md-block"
        @click="getData"
      >
        Filter
      </md-button>

      <small>Found {{ students.length }} Students.</small>
    </template>

    <ul class="list-group list-group-flush">
      <li
        v-for="s in students"
        :key="s.holiday_id"
        class="list-group-item"
      >
        <b>{{ s.programme.student.full_name }}</b>

        <md-button
          class="md-just-icon md-primary md-round"
          @click="goToProgramme(s.programme_id, false)"
          @click.middle="goToProgramme(s.programme_id, true)"
        >
          <md-icon>description</md-icon>
          <md-tooltip>Go To Programme</md-tooltip>
        </md-button>
      </li>
    </ul>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import moment from 'moment';
import { FormDate } from '@/components/Inputs';

export default {
  components: {
    Widget,
    FormDate,
  },
  data() {
    return {
      students: [],
      filters: {
        date: null,
      },
    };
  },
  methods: {
    setDefaultDate() {
      const dayINeed = 1; // for Monday
      const today = moment().isoWeekday();

      // if we haven't yet passed the day of the week that I need:
      if (today <= dayINeed) {
        // then just give me this week's instance of that day
        this.filters.date = moment().isoWeekday(dayINeed);
      } else {
        // otherwise, give me *next week's* instance of that same day
        this.filters.date = moment().add(1, 'weeks').isoWeekday(dayINeed);
      }
    },
    goToProgramme(programmeId, isMiddleClick) {
      this.openLink({ name: 'ProgrammeDetails', params: { id: programmeId } }, isMiddleClick);
    },
    getData() {
      if (!this.filters.date) {
        this.setDefaultDate();
      }

      this.request('ending_holidays/report', 'get', { ...this.filters }, ({ data }) => {
        this.students = data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-group-item {
  display: flex;
  align-items: center;

  .md-button {
    margin: 0;
    margin-left: auto;
  }
}
</style>
