<template>
  <Widget
    title="Forms to Approve"
    icon="list"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <ul
      v-if="forms.length"
      class="list-group list-group-flush m-0"
    >
      <li
        v-for="(s,i) of forms"
        :key="i"
        class="list-group-item"
      >
        <a
          class="float-right"
          @click="selForm = s"
        >
          <md-icon class="pointer text-warning">
            search
            <md-tooltip md-direction="top">
              Show Details
            </md-tooltip>
          </md-icon>
        </a>

        <b>{{ s.email }}</b> <br>
        <small>{{ s.status }}</small> <br>
        <small>Total Package: €{{ s.details.package_total.toFixed(2) }}</small>
      </li>
    </ul>
    <p
      v-else
      class="text-center"
    >
      There are no forms to approve
    </p>

    <FormsModal
      v-if="selForm"
      ref="modal"
      :item="selForm"
      @close="selForm = null"
      @onApprove="onApprove"
      @onSave="getData"
    />
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import FormsModal from '@/pages/Dashboard/Sales/Forms/FormsModal.vue';

export default {
  components: {
    FormsModal,
    Widget,
  },
  data: () => ({
    forms: [],
    selForm: null,
  }),
  methods: {
    getData() {
      this.request('student_forms', 'get', {
        status: 'Fully Answered',
        show_inactive: false,
      }, ({ data }) => {
        this.forms = data;
      });
    },
    onApprove(item) {
      this.request(`student_forms/${item.student_form_id}/approve`, 'put', null, () => {
        // reset table
        this.getData();
        this.fireSuccess('Student created');
        this.$refs.modal.close();
      });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
i.md-icon {
    font-size: 1.3em !important;
    vertical-align: sub;
}
.widgetBody {
  max-height: 600px;
  overflow: auto;
}
</style>
