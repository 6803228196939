<template>
  <Widget
    title="Income / Expenditure"
    icon="assessment"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <template slot="filters">
      <PaymentMethodsSelect
        v-model="filters.payment_method_id"
        with-deleted
        all-option="All"
      />
      <md-button
        class="md-info md-sm md-block"
        @click="getData"
      >
        Filter
      </md-button>
    </template>

    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th width="1%">
            Month
          </th>
          <th>In</th>
          <th>Out</th>
          <th>Balance</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="f of flow"
          :key="f.month"
        >
          <td>{{ f.month }}</td>
          <td class="text-right">
            {{ f.in.toFixed(2) | currency('€') }}
          </td>
          <td class="text-right">
            {{ f.out.toFixed(2) | currency('€') }}
          </td>
          <td
            class="text-right"
            :class="{
              red: f.in-f.out <= 0,
              green: f.in-f.out > 0,
            }"
          >
            {{ (f.in-f.out).toFixed(2) | currency('€') }}
          </td>
        </tr>
      </tbody>
    </table>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import moment from 'moment';
import { PaymentMethodsSelect } from '@/components/Inputs/selects';

export default {
  components: {
    PaymentMethodsSelect,
    Widget,
  },
  data: () => ({
    flow: [],
    init_flow: [
      {
        month: 'Jan',
        in: 0,
        out: 0,
      },
      {
        month: 'Feb',
        in: 0,
        out: 0,
      },
      {
        month: 'Mar',
        in: 0,
        out: 0,
      },
      {
        month: 'Apr',
        in: 0,
        out: 0,
      },
      {
        month: 'May',
        in: 0,
        out: 0,
      },
      {
        month: 'Jun',
        in: 0,
        out: 0,
      },
      {
        month: 'Jul',
        in: 0,
        out: 0,
      },
      {
        month: 'Aug',
        in: 0,
        out: 0,
      },
      {
        month: 'Sep',
        in: 0,
        out: 0,
      },
      {
        month: 'Oct',
        in: 0,
        out: 0,
      },
      {
        month: 'Nov',
        in: 0,
        out: 0,
      },
      {
        month: 'Dec',
        in: 0,
        out: 0,
      },
    ],
    filters: {
      payment_method_id: 'all',
    },
  }),
  methods: {
    getData() {
      this.request(`${this.$API.MONEYFLOW}report`, 'get', {
        year: moment().format('Y'),
        ...this.filters,
      }, ({ data }) => {
        this.flow = JSON.parse(JSON.stringify(this.init_flow));

        data.forEach((x) => {
          this.flow[x.month - 1].in = parseFloat(x.income);
          this.flow[x.month - 1].out = parseFloat(x.outcome) * -1;
        });
      });
    },
  },
};
</script>

<style scoped>
td.red {
  color: red;
  font-weight: 600;
}
td.green {
  color: green;
  font-weight: 600;
}
</style>
