<template>
  <Widget
    title="Overpaid Packages"
    icon="list"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <ul class="list-group list-group-flush m-0">
      <li
        v-for="(s,i) of students"
        :key="i"
        class="list-group-item"
      >
        <router-link
          :to="{
            name: 'StudentFinancial',
            params: { id: s.student_id },
            query: { pid: s.programme_id }
          }"
          class="float-right"
        >
          <md-icon class="pointer md-success">
            double_arrow
            <md-tooltip md-direction="top">
              Go To Financial Profile
            </md-tooltip>
          </md-icon>
        </router-link>

        <b>{{ s.name }}</b> <br>
        <small>
          {{ `Overpaid: ` }}
          {{ (s.total_paid - s.total_package).toFixed(2) | currency('€') }}
        </small>
      </li>
    </ul>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';

export default {
  components: {
    Widget,
  },
  data: () => ({
    students: [],
  }),
  methods: {
    getData() {
      this.request(this.$API.STUDENTS_OVERPAID, 'get', null, (data) => {
        this.students = data.data;
      });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
i.md-icon {
    font-size: 1.3em !important;
    vertical-align: sub;
}
.widgetBody {
  max-height: 600px;
  overflow: auto;
}
</style>
