<template>
  <Widget
    title="Students Starting"
    icon="track_changes"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <div slot="filters">
      <form-date
        v-model="filters.from"
        name="from"
        label="From..."
        class="pr-2 w-50 d-inline-block"
      />
      <form-date
        v-model="filters.to"
        name="to"
        label="To..."
        class="pl-2 w-50 d-inline-block"
      />
      <AgentsSelect
        v-if="userIsAgentsManager"
        v-model="filters.agent_id"
        class="w-100"
        :agent-groups="agentGroupsManagerIds"
      />
      <md-button
        class="md-info md-sm md-block"
        @click="getData(false)"
      >
        Filter
      </md-button>
      <md-button
        class="md-primary md-sm md-block"
        @click="getData(true)"
      >
        Download as XLS
      </md-button>
      <small>Found {{ students.length }} Students.</small>
    </div>

    <ul class="list-group list-group-flush">
      <li
        v-for="s in students"
        :key="s.programme_id"
        class="list-group-item"
      >
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <b>{{ s.student.full_name }}</b> <br>
            <small>Agent: <b>{{ s.agent.name }}</b></small> <br>
            <small>Starting {{ dateFormat(s.start_date) }}</small>
          </div>
          <div>
            <md-button
              class="md-simple md-just-icon"
              :to="{ name:'StudentResume', params: {id: s.student_id} }"
            >
              <md-icon>double_arrow</md-icon>
              <md-tooltip md-direction="top">
                Go To Student Resume
              </md-tooltip>
            </md-button>
          </div>
        </div>
      </li>
    </ul>
  </Widget>
</template>

<script>
import moment from 'moment';
import Widget from '@/components/Widget.vue';
import { FormDate } from '@/components/Inputs';
import { AgentsSelect } from '@/components/Inputs/selects';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    Widget,
    FormDate,
    AgentsSelect,
  },
  data() {
    return {
      students: [],
      filters: {
        from: moment(),
        to: moment().add(1, 'month'),
      },
    };
  },
  methods: {
    getData(download = false) {
      if (!download) {
        this.request(this.$API.STUDENTS_STARTING, 'get', { ...this.filters }, ({ data }) => {
          this.students = data;
        });
      } else {
        this.request(this.$API.STUDENTS_STARTING, 'download', { ...this.filters, download }, ({ data }) => {
          const filename = 'Students_starting_report.xlsx';
          downloadFile(data, filename);
        });
      }
    },
  },
};
</script>
