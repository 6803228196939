<template>
  <Widget
    title="Sales Per Country"
    icon="language"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <template slot="filters">
      <form-date
        v-model="filters.from"
        name="from"
        label="From..."
        class="pr-2 w-50 d-inline-block"
      />
      <form-date
        v-model="filters.to"
        name="to"
        label="To..."
        class="pl-2 w-50 d-inline-block"
      />
      <md-button
        class="md-info md-sm md-block"
        @click="getData"
      >
        Filter
      </md-button>
    </template>

    <div class="alert alert-warning text-center">
      This Chart includes all the Programmes created in
      the last 12 months or between the selected dates
    </div>

    <pie-chart
      v-if="loaded"
      :chart-data="sales"
      :options="{
      }"
      class="responsive-height"
    />
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import PieChart from '@/components/Charts/PieChart.vue';
import { FormDate } from '@/components/Inputs';

export default {
  components: {
    PieChart,
    FormDate,
    Widget,
  },
  data: () => ({
    sales: null,
    loaded: false,
    filters: {
      from: null,
      to: null,
    },
  }),
  methods: {
    getData() {
      this.loaded = false;
      this.request(this.$API.SALES_PER_COUNTRY, 'get', { ...this.filters }, ({ data }) => {
        this.sales = {
          datasets: [{
            data: [],
            backgroundColor: [],
            borderWidth: 1,
          }],
          labels: [],
        };

        let i = 0;
        const others = {
          percentage: 0,
          qty: 0,
        };
        // eslint-disable-next-line no-restricted-syntax
        for (const x of data) {
          if (x.qty) {
            const amount = this.$options.filters.currency(parseFloat(x.qty), '€');

            if (i > 6) {
              others.percentage += x.percentage;
              others.qty += parseFloat(x.qty);
            } else {
              this.sales.labels.push(`${x.name} - ${amount} (${x.percentage.toFixed(2)}%)`);
              this.sales.datasets[0].data.push(parseFloat(x.qty));
            }
            i += 1;
          }
        }

        if (others.qty > 0) {
          const amount = this.$options.filters.currency(parseFloat(others.qty), '€');
          this.sales.labels.push(`Others - ${amount} (${others.percentage.toFixed(2)}%)`);
          this.sales.datasets[0].data.push(parseFloat(others.qty));
        }

        this.loaded = true;
      });
    },
  },
};
</script>

<style>

</style>
