<script>
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { Bar, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    colorScheme: [
      '#25CCF7',
      '#2592f7',
      '#252ff7',
      '#9823f7',
      '#e072ce',
      '#e85864',
      '#de6133',
      '#f0d043',
    ],
  }),
  mounted() {
    const auxData = this.chartData;
    const auxOptions = {
      ...this.options,
      maintainAspectRatio: false,
    };

    let index = 0;
    for (const a of auxData.datasets) {
      a.backgroundColor = this.colorScheme[index];
      index += 1;
    }
    this.renderChart(auxData, auxOptions);
  },
};
</script>
