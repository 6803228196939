<template>
  <Widget
    title="Students Over Attended"
    icon="track_changes"
    :class="{'md-danger': students.length > 0}"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <small>Found {{ students.length }} Students.</small>

    <ul class="list-group list-group-flush">
      <li
        v-for="s in students"
        :key="s.programme_id"
        class="list-group-item"
      >
        <router-link
          class="float-right"
          :to="{name:'ProgrammeDetails', params: {id: s.programme_id}}"
        >
          <md-icon class="pointer md-success">
            double_arrow
            <md-tooltip md-direction="top">
              Go To Programme Details
            </md-tooltip>
          </md-icon>
        </router-link>
        <p>
          <b>{{ s.id }} | {{ s.full_name }}</b> <br>
          <small>
            Assisted: {{ parseInt(s.performed_days,10) }} over
            {{ parseInt(s.total_days) }} days
          </small>
        </p>
      </li>
    </ul>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';

export default {
  components: {
    Widget,
  },
  data() {
    return {
      students: [],
    };
  },
  methods: {
    getData() {
      this.request(this.$API.STUDENTS_OVERASSISTED, 'get', null, ({ data }) => {
        this.students = data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-card.md-danger {
  background: #f5c6cb;

  ::v-deep .md-card-header,::v-deep .md-card-content {
    background: #f5c6cb;
  }

  .list-group-item {
    background: transparent;

    p {
      line-height: 1em;
    }
  }
}
</style>
