<template>
  <Widget
    title="Deleted Moneyflows"
    icon="track_changes"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <template slot="filters">
      <form-date
        v-model="filters.from"
        name="from"
        label="Deleted From..."
        class="pr-2 w-50 d-inline-block"
      />
      <form-date
        v-model="filters.to"
        name="to"
        label="Deleted To..."
        class="pr-2 w-50 d-inline-block"
      />
      <md-button
        class="md-info md-sm md-block"
        @click="getData"
      >
        Filter
      </md-button>
    </template>

    <ul
      v-if="flows.length"
      class="list-group list-group-flush"
    >
      <li
        v-for="f in flows"
        :key="f.moneyflow_id"
        class="list-group-item"
      >
        <md-button
          class="md-info float-right"
          @click="selPaymentDetails = f"
        >
          Details
        </md-button>
        <b>Total:</b>{{ parseFloat(f.total).toFixed(2) | currency('€') }} <br>
        <b>Deleted at:</b> {{ dateFormat(f.deleted_at) }} <br>
        <b>Deleted by:</b> {{ f.deleter.name }} <br>
      </li>
    </ul>
    <p
      v-else
      class="text-center"
    >
      There are no Deleted Moneyflows within the selected period
    </p>

    <MoneyflowDetails
      v-if="selPaymentDetails"
      :item="selPaymentDetails"
      @close="selPaymentDetails = null"
    />
  </Widget>
</template>

<script>
import moment from 'moment';
import Widget from '@/components/Widget.vue';
import MoneyflowDetails from '@/pages/Dashboard/Financial/MoneyflowDetails.vue';
import {
  FormDate,
} from '@/components/Inputs';

export default {
  components: {
    Widget,
    MoneyflowDetails,
    FormDate,
  },
  data() {
    return {
      selPaymentDetails: null,
      flows: [],
      filters: {
        from: moment().subtract(1, 'month'),
        to: moment(),
      },
    };
  },
  methods: {
    getData() {
      this.request(`${this.$API.MONEYFLOW}deleted`, 'get', { ...this.filters }, ({ data }) => {
        this.flows = data;
      });
    },
  },
};
</script>
