<template>
  <Widget
    title="Payments to Approve"
    icon="list"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <ul class="list-group list-group-flush m-0">
      <li
        v-for="(s,i) of payments"
        :key="i"
        class="list-group-item"
      >
        <div class="d-flex">
          <div class="flex-grow-1">
            <b>{{ s.package.programme.student.full_name }}</b>
            - {{ s.package.programme.student.student_number }}<br>
            <small>{{ `Expected Payment Date: ${dateFormat(s.payment_date)}` }}</small> <br>
            <small>{{ `File Title: ${s.file.title}` }}</small> <br>
            <small>{{ `Uploaded Date: ${dateFormat(s.file.created_at)}` }}</small> <br>
            <small>{{ `Amount: €${s.total}` }}</small>
          </div>
          <div class="d-flex align-items-center">
            <a
              href="#"
              @click.prevent="openFileUrl(s.file.file_id)"
            >
              <md-icon class="pointer md-primary pr-2">
                download
                <md-tooltip md-direction="top">
                  Download Proof of Payment
                </md-tooltip>
              </md-icon>
            </a>
            <router-link
              :to="{
                name: 'PackagePayments',
                params: { id: s.package_id }
              }"
            >
              <md-icon class="pointer md-success">
                double_arrow
                <md-tooltip md-direction="top">
                  Go To Package Payments
                </md-tooltip>
              </md-icon>
            </router-link>
          </div>
        </div>
      </li>
    </ul>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';

export default {
  components: {
    Widget,
  },
  data: () => ({
    payments: [],
  }),
  methods: {
    getData() {
      this.request(this.$API.PAYMENTS_TO_APPROVE, 'get', null, ({ data }) => {
        this.payments = data;
      });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
i.md-icon {
    font-size: 1.3em !important;
    vertical-align: sub;
}
.widgetBody {
  max-height: 600px;
  overflow: auto;
}
</style>
