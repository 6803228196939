<template>
  <Widget
    title="Average Attendance per Classroom"
    icon="donut_large"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <ul class="list-group list-group-flush">
      <li
        v-for="(c,i) of classrooms"
        :key="i"
        class="list-group-item"
      >
        Classroom {{ `${c.classroom_code} | ${c.classroom_name.name}` }}
        <b>({{ c.attendance_average.attendance_average }}%)</b> <br>
        <md-progress-bar
          class="mt-2 md-info"
          md-mode="determinate"
          :md-value="parseFloat(c.attendance_average.attendance_average)"
        />
      </li>
    </ul>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';

export default {
  components: {
    Widget,
  },
  data: () => ({
    classrooms: [],
  }),
  methods: {
    getData() {
      this.request(this.$API.CLASSROOMS, 'get', {
        attendance_average: true,
      }, (data) => {
        this.classrooms = data.data;
      });
    },
  },
};
</script>

<style>

</style>
