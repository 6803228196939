<template>
  <Widget
    title="Payments Overdue"
    icon="list"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <ul class="list-group list-group-flush m-0">
      <li
        v-for="(s,i) of payments"
        :key="i"
        class="list-group-item"
      >
        <router-link
          :to="{
            name: 'PackagePayments',
            params: { id: s.package_id }
          }"
          class="float-right"
        >
          <md-icon class="pointer md-success">
            double_arrow
            <md-tooltip md-direction="top">
              Go To Package Payments
            </md-tooltip>
          </md-icon>
        </router-link>

        <b>{{ s.package.programme.student.full_name }}</b> <br>
        <small>
          Programme Starting Date: {{ dateFormat(s.package.programme.start_date) }}
        </small> <br>
        <small>{{ `Expected Payment Date: ${dateFormat(s.payment_date)}` }}</small> <br>
        <small>{{ `Amount: €${s.total}` }}</small>
      </li>
    </ul>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';

export default {
  components: {
    Widget,
  },
  data: () => ({
    payments: [],
  }),
  methods: {
    getData() {
      this.request(this.$API.PAYMENTS_OVERDUE, 'get', null, ({ data }) => {
        // Order ASC by Start Date
        this.payments = data.sort((a, b) => {
          if (a.package.programme.start_date <= b.package.programme.start_date) return -1;
          return 1;
        });
      });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
i.md-icon {
    font-size: 1.3em !important;
    vertical-align: sub;
}
.widgetBody {
  max-height: 600px;
  overflow: auto;
}
</style>
