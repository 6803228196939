<template>
  <Widget
    title="Pending Attendance List"
    icon="list"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <template slot="filters">
      <MonthsSelect
        v-model="filters.month"
        all-option="All"
      />
      <YearsSelect
        v-model="filters.year"
        all-option="All"
      />
    </template>

    <ul
      v-if="classrooms.length"
      class="list-group list-group-flush m-0"
    >
      <li
        v-for="(c,i) of classrooms"
        :key="i"
        class="list-group-item"
      >
        <b>Classroom {{ `${c.classroom_code} | ${c.classroom_name.name}` }}</b>

        <ul>
          <li
            v-for="(p,z) of c.pending_attendance_lists"
            :key="z"
          >
            Attendance List {{ dateFormat(p.date) }}

            <router-link
              :to="{
                name: 'AttendanceDetails',
                params: { id: p.attendance_list_id }
              }"
            >
              <md-icon class="pointer md-success">
                double_arrow
                <md-tooltip md-direction="top">
                  Go To Attendance List
                </md-tooltip>
              </md-icon>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
    <p
      v-else
      class="text-center"
    >
      There are no Pending Attendance Lists for this period
    </p>
  </Widget>
</template>

<script>
import moment from 'moment';
import Widget from '@/components/Widget.vue';
import { MonthsSelect, YearsSelect } from '@/components/Inputs/selects';

export default {
  components: {
    Widget,
    MonthsSelect,
    YearsSelect,
  },
  data: () => ({
    classrooms: [],
    filters: {
      month: moment().format('M'),
      year: moment().format('YYYY'),
    },
  }),
  methods: {
    sortAttLists(a, b) {
      return (a.date > b.date ? 1 : -1);
    },
    getData() {
      this.request(this.$API.CLASSROOMS, 'get', {
        pending_attendance_list: true,
        ...this.filters,
      }, (data) => {
        this.classrooms = data.data.map((x) => ({
          ...x,
          pending_attendance_lists: x.pending_attendance_lists.sort(this.sortAttLists),
        }));
      });
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
i.md-icon {
    font-size: 1.3em !important;
    vertical-align: sub;
}
.widgetBody {
  max-height: 600px;
  overflow: auto;
}
::v-deep .md-card-header {
  position: relative;

  .fixed-filters {
    margin-top: 25px;
    display: flex;
    justify-content: stretch;

    &.column {
      flex-direction: column;
    }

    > * {
      flex: 1;
      margin: 0 5px;
    }
  }
}
</style>
