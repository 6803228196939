<template>
  <Widget
    title="Students Per Country"
    icon="person"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <div slot="filters">
      <ProgrammeStatusesSelect
        v-model="filters.status"
        multiple
      />
      <md-button
        class="md-info md-sm md-block"
        @click="getData"
      >
        Filter
      </md-button>

      <div class="alert alert-warning text-center">
        This Chart includes all the Students that have Programmes not finished yet
      </div>
    </div>

    <div
      v-if="loaded && !tableData.length"
      class="alert alert-warning"
    >
      There is no Students with this filters
    </div>
    <template v-else-if="loaded">
      <pie-chart
        :chart-data="countries"
        :options="{
        }"
        class="responsive-height"
      />
      <md-table
        v-model="tableData"
        table-header-color="green"
        class="students-table"
      >
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
        >
          <md-table-cell md-label="Country">
            <img
              :src="item.flag"
              class="flag"
            >
            {{ item.name }}
          </md-table-cell>
          <md-table-cell md-label="Quantity">
            {{ item.qty }}
          </md-table-cell>
          <md-table-cell md-label="Percentage">
            {{ item.percentage.toFixed(2) }}%
          </md-table-cell>
        </md-table-row>
      </md-table>
    </template>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import PieChart from '@/components/Charts/PieChart.vue';
import { ProgrammeStatusesSelect } from '@/components/Inputs/selects';

export default {
  components: {
    Widget,
    PieChart,
    ProgrammeStatusesSelect,
  },
  data: () => ({
    countries: null,
    tableData: [],
    loaded: false,
    filters: {
      status: [],
    },
  }),
  methods: {
    getData() {
      this.loaded = false;
      this.request(this.$API.STUDENTS_PER_COUNTRY, 'get', { ...this.filters }, ({ data }) => {
        this.tableData = data;
        this.countries = {
          datasets: [{
            data: [],
            backgroundColor: [],
            borderWidth: 1,
          }],
          labels: [],
        };

        // eslint-disable-next-line no-restricted-syntax
        for (const x of data) {
          if (x.qty) {
            this.countries.labels.push(`${x.name} (${x.percentage.toFixed(2)}%)`);
            this.countries.datasets[0].data.push(parseFloat(x.qty));
          }
        }

        this.loaded = true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.students-table {
  width: 100%;
  padding-left: 20px;
  .flag {
    width: 25px;
  }
}
</style>
