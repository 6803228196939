<template>
  <Widget
    title="Students 'Enrolled' without Enrolment"
    icon="track_changes"
    :class="{'md-danger': students.length > 0}"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <small>Found {{ students.length }} Students.</small>

    <ul class="list-group list-group-flush">
      <li
        v-for="s in students"
        :key="s.programme_id"
        class="list-group-item"
      >
        <b>{{ s.student.full_name }}</b>
        - <router-link :to="{name:'ProgrammeDetails', params: {id: s.programme_id}}">
          Go To Programme Details
        </router-link>
      </li>
    </ul>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';

export default {
  components: {
    Widget,
  },
  data() {
    return {
      students: [],
    };
  },
  methods: {
    getData() {
      this.request(this.$API.STUDENTS_ENROLMENT_EMPTY, 'get', null, ({ data }) => {
        this.students = data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep  .widget-card.md-danger {
  background: #f5c6cb;

  .md-card-header, .md-card-content {
    background: #f5c6cb;
  }

  .list-group-item {
    background: transparent;
  }
}
</style>
