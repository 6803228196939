<template>
  <Widget
    title="Files Uploaded <small> - last 7 days</small>"
    icon="track_changes"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <template slot="filters">
      <md-switch v-model="filters.show_checked">
        Show Checked
      </md-switch>
      <file-types-select
        v-model="filters.file_type_id"
        label="File Type"
        class="w-100 d-inline-block"
      />
      <form-date
        v-model="filters.start_date"
        label="Course Start Date"
        class="w-100 d-inline-block"
      />
      <md-button
        class="md-info md-sm md-block"
        @click="getData"
      >
        Filter
      </md-button>
    </template>

    <ul class="list-group list-group-flush">
      <li
        v-for="(c,i) of items"
        :key="i"
        class="list-group-item"
      >
        <div class="d-flex align-items-center">
          <div
            class="flex-grow-1"
            :class="{'checked-file': c.checked}"
          >
            <b>{{ c.student.student_number }}</b> <br>
            <b>{{ c.student.full_name }}</b> <br>
            <b>Course Start Date: {{ c.student.start_date }}</b> <br>
            <small class="t-grey">{{ dateTimeFormat(c.created_at) }}:</small> {{ c.message }}
          </div>
          <div class="actions">
            <div
              class="actions-check"
              @click="checkFile(c)"
            >
              <md-icon :class="{'actions-check-icon': c.checked}">
                done_outline
              </md-icon>
              <md-tooltip md-direction="top">
                Check File
              </md-tooltip>
            </div>

            <md-button
              class="md-simple md-just-icon"
              :to="{name:'StudentResume', params: {id: c.student.student_id}}"
            >
              <md-icon>double_arrow</md-icon>
              <md-tooltip md-direction="top">
                Go To Student Resume
              </md-tooltip>
            </md-button>
          </div>
        </div>
      </li>
    </ul>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import FileTypesSelect from '@/components/Inputs/selects/FileTypesSelect.vue';
import FormDate from '@/components/Inputs/FormDate.vue';

export default {
  components: {
    FileTypesSelect,
    FormDate,
    Widget,
  },
  data: () => ({
    items: [],
    filters: {
      file_type_id: null,
      show_checked: false,
    },
  }),
  methods: {
    getData() {
      this.request('files_uploaded/report', 'get', this.filters, ({ data }) => {
        this.items = data;
      });
    },
    checkFile(item) {
      if (item.checked) {
        this.fireWarning('File already checked successfully');
      } else {
        item.checked = true;
        this.request(`students/${item.student.student_id}/files/${item.timeline_id}/check`, 'put', null, () => {
          this.fireSuccess('File checked succesfully');
          this.getData();
        });
      }
    },
    isString(a) {
      return typeof a === 'string';
    },
  },
};
</script>

<style lang="scss" scoped>
.t-grey {
  color: #808080;
}

@mixin checked-color() {
  color: var(--success) !important;
}

.checked-file b {
  @include checked-color;
}

.actions {
  display: flex;
  align-items: center;

  &-check {
    cursor: pointer;

    &-icon {
      @include checked-color;
    }
  }
}
</style>
