<template>
  <Widget
    title="Wrong Tuition Finish Date Programmes Enrolments"
    icon="track_changes"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <ul
      v-if="enrolments.length"
      class="list-group list-group-flush"
    >
      <li
        v-for="(e,i) of enrolments"
        :key="i"
        class="list-group-item"
      >
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <b>Name:</b> {{ e.full_name }}<br>
            <b>Programme Tuition Finish date:</b> {{ dateFormat(e.tuition_finish_date) }}
          </div>
          <div>
            <router-link
              :to="{
                name: 'ProgrammeDetails',
                params: { id: e.programme_id }
              }"
              class="float-right"
            >
              <md-icon class="pointer md-success">
                double_arrow
                <md-tooltip md-direction="top">
                  Go To Programme
                </md-tooltip>
              </md-icon>
            </router-link>
          </div>
        </div>
      </li>
    </ul>

    <p
      v-else
      class="text-center"
    >
      There are no Wrong Tuitions ending this week
    </p>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';

export default {
  components: {
    Widget,
  },
  data: () => ({
    enrolments: [],
  }),
  methods: {
    getData() {
      this.request('wrong_tuition_programmes/report', 'get', null, ({ data }) => {
        this.enrolments = data;
      });
    },
    isString(a) {
      return typeof a === 'string';
    },
  },
};
</script>
