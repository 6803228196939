<template>
  <Widget
    title="Application Forms Status"
    icon="track_changes"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <template slot="filters">
      <div
        v-if="userIsAgentsManager"
      >
        <AgentsSelect
          v-model="filters.agent_id"
          class="w-100"
          :agent-groups="agentGroupsManagerIds"
        />
        <md-button
          class="md-info md-sm md-block"
          @click="getData"
        >
          Filter
        </md-button>
      </div>

      <div class="d-flex">
        <md-button
          class="md-primary md-sm w-50"
          @click="show = 'status'"
        >
          Statuses
        </md-button>
        <md-button
          class="md-warning md-sm w-50 ml-1"
          @click="show = 'tc'"
        >
          T&C
        </md-button>
      </div>
    </template>

    <div>
      <ul class="list-group list-group-flush">
        <li
          v-for="s in Object.keys(forms[show])"
          :key="s"
          class="list-group-item"
        >
          <b>{{ s }}:</b> {{ forms[show][s] }}
        </li>
      </ul>
    </div>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import { AgentsSelect } from '@/components/Inputs/selects';

export default {
  components: {
    Widget,
    AgentsSelect,
  },
  data() {
    return {
      forms: {
        status: {},
        tc: {},
      },
      filters: {
        agent_id: null,
      },
      show: 'status',
    };
  },
  methods: {
    getData() {
      this.request('student_forms/status_report', 'get', { ...this.filters }, ({ data }) => {
        this.forms = data;
      });
    },
  },
};
</script>
