<template>
  <Widget
    title="Low Attendance Rate"
    icon="track_changes"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <template slot="filters">
      <ProgrammeStatusesSelect
        v-model="filters.status"
        multiple
      />
      <md-button
        class="md-info md-sm md-block"
        @click="getData"
      >
        Filter
      </md-button>

      <small>Found {{ programmes.length }} Students.</small>
    </template>

    <ul class="list-group list-group-flush">
      <li
        v-for="p in programmes"
        :key="p.programme_id"
        class="list-group-item"
      >
        <div>
          <b>{{ p.student.student_number }}</b> <br>
          <b>{{ p.student.full_name }}</b> <br>
          <b>Start Date:</b> {{ dateFormat(p.programme.start_date) }} <br>
          <b>Att. rate: </b> {{ p.attendance_rate }}% <br>
          <b>Remaining Days: </b> {{ calculateRemainingDays(p) }} <br>
        </div>

        <md-button
          class="md-just-icon md-primary md-round"
          @click="goToProgramme(p.programme_id, false)"
          @click.middle="goToProgramme(p.programme_id, true)"
        >
          <md-icon>description</md-icon>
          <md-tooltip>Go To Programme</md-tooltip>
        </md-button>
      </li>
    </ul>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import ProgrammeStatusesSelect from '@/components/Inputs/selects/ProgrammeStatusesSelect.vue';

export default {
  components: {
    Widget,
    ProgrammeStatusesSelect,
  },
  data() {
    return {
      programmes: [],
      filters: {
        status: null,
      },
    };
  },
  methods: {
    goToProgramme(id, isMiddleClick) {
      this.openLink({ name: 'ProgrammeDetails', params: { id } }, isMiddleClick);
    },
    getData() {
      this.request('attendance/low_report', 'get', this.filters, ({ data }) => {
        this.programmes = data;
      });
    },
    calculateRemainingDays(p) {
      const totalDays = p.programme.course.weekdays.length * p.programme.course_weeks;
      const performed = parseInt(p.performed_days, 10);

      return totalDays - performed;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-group-item {
  display: flex;
  align-items: center;

  .md-button {
    margin: 0;
    margin-left: auto;
  }
}
</style>
