<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 text-right">
      <md-button
        class="md-simple md-primary md-sm"
        @click="addWidget"
      >
        Add Widget
        <md-icon>add</md-icon>
      </md-button>
    </div>
    <div
      v-if="!widgets.length"
      class="md-layout-item md-size-100 text-center"
    >
      <md-card>
        <md-card-content>
          <p>There are no Widgets to show. You can add up to 10 from the Button above</p>
        </md-card-content>
      </md-card>
    </div>

    <div
      v-for="widget in widgets"
      :key="widget.user_widget_id"
      class="md-layout-item"
      :class="'md-size-'+parseInt(widget.col_size)"
    >
      <div
        :is="widget.name"
        @onOpenSettings="openSettings(widget)"
        @onDeleteWidget="deleteWidget(widget)"
      />
    </div>

    <WidgetSettings
      v-if="selWidget"
      :widget="selWidget"
      :max-position="widgets.length"
      @close="selWidget = null"
      @onSave="settingsSaved"
    />
  </div>
</template>

<script>
import Widget from '@/components/Widget.vue';
import WidgetSettings from './WidgetSettings.vue';
import {
  ChangelogWidget,
  SalesPerCountryWidget,
  StudentsPerCountryWidget,
  ProgrammesPerLevelWidget,
  InflowOutflowWidget,
  ProgrammeStatusWidget,
  TeacherAttendanceAverageWidget,
  PendingAttendanceListWidget,
  ProgrammeClassTimeWidget,
  StudentsFinishingWidget,
  StudentsNoEnrolmentWidget,
  StudentsStartingWidget,
  OutflowByCategoryWidget,
  DeletedMoneyflowsWidget,
  OverpaidStudentsWidget,
  PaymentsToApproveWidget,
  PaymentsOverdueWidget,
  FormsToApproveWidget,
  StudentsOverAttendedWidget,
  EndingHolidaysWidget,
  OnlineUsersWidget,
  WrongTuitionFinishDateWidget,
  WeekAbsentStudentsWidget,
  FilesUploadedWidget,
  ApplicationFormsStatusWidget,
  OverenrolledClassroomsWidget,
  ClassroomNumbersWidget,
  PackageItemsFromIncomeWidget,
  SalesPerMonthByAgentWidget,
  LowAttendanceWidget,
  StudentsNotInKmpusWidget,
  StaffNotInKmpusWidget,
  IncomeProjectionWidget,
} from './Widgets';

export default {
  components: {
    Widget,
    OnlineUsersWidget,
    WidgetSettings,
    ChangelogWidget,
    SalesPerCountryWidget,
    StudentsPerCountryWidget,
    ProgrammesPerLevelWidget,
    InflowOutflowWidget,
    ProgrammeStatusWidget,
    TeacherAttendanceAverageWidget,
    PendingAttendanceListWidget,
    ProgrammeClassTimeWidget,
    StudentsStartingWidget,
    StudentsFinishingWidget,
    OutflowByCategoryWidget,
    StudentsNoEnrolmentWidget,
    DeletedMoneyflowsWidget,
    OverpaidStudentsWidget,
    PaymentsToApproveWidget,
    PaymentsOverdueWidget,
    FormsToApproveWidget,
    StudentsOverAttendedWidget,
    EndingHolidaysWidget,
    WrongTuitionFinishDateWidget,
    WeekAbsentStudentsWidget,
    FilesUploadedWidget,
    ApplicationFormsStatusWidget,
    OverenrolledClassroomsWidget,
    ClassroomNumbersWidget,
    PackageItemsFromIncomeWidget,
    SalesPerMonthByAgentWidget,
    LowAttendanceWidget,
    StudentsNotInKmpusWidget,
    StaffNotInKmpusWidget,
    IncomeProjectionWidget,
  },
  data: () => ({
    widgets: [],
    selWidget: null,
    loading: false,
  }),
  mounted() {
    this.getWidgets();
  },
  methods: {
    openSettings(widget) {
      this.selWidget = { ...widget };
    },
    getWidgets() {
      this.loading = true;
      this.request(this.$API.USER_WIDGETS, 'get', null, ({ data }) => {
        this.widgets = data;
      }, () => {
        this.loading = false;
      });
    },
    settingsSaved(widget) {
      this.loading = true;

      if (widget.user_widget_id) {
        this.request(this.$API.USER_WIDGETS + widget.user_widget_id, 'put', widget, () => {
          this.fireSuccess('Widget Updated succesfully');
          this.getWidgets();
        }, () => {
          this.loading = false;
        });
      } else {
        this.request(this.$API.USER_WIDGETS, 'post', widget, () => {
          this.fireSuccess('Widget Created succesfully');
          this.getWidgets();
        }, () => {
          this.loading = false;
        });
      }
    },
    addWidget() {
      this.selWidget = {};
    },
    deleteWidget(widget) {
      this.loading = true;

      this.request(this.$API.USER_WIDGETS + widget.user_widget_id, 'delete', null, () => {
        this.fireSuccess('Widget Deleted succesfully');
        this.getWidgets();
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.widget-card {
  margin: 15px 0;
  position: static;

  .md-card-header {
    .title {
      font-weight: 800;
    }

    .card-settings {
      border-radius: 3px;
      margin-top: 0;
      margin-right: 0;
      float: right;

      .md-icon {
        font-size: 1.4em !important;
        cursor: pointer;
      }
    }
  }
}
.grid-container {
  display: grid;
}

</style>
