<template>
  <Widget
    title="Programmes Per Level"
    icon="spellcheck"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <template slot="filters">
      <div class="alert alert-warning text-center">
        This Chart includes all the Programmes with Start Date before today
        and Finish Date after today.
      </div>

      <PeriodsSelect
        v-model.number="filters.class_schedule_id"
        @input="getSales"
      />
    </template>

    <pie-chart
      v-if="loaded"
      :chart-data="programmes"
      :options="{}"
      class="responsive-height w-100"
    />
    <div
      v-if="programmes.datasets[0].data.length === 0"
      class="empty-data"
    >
      <md-icon>
        search
      </md-icon>
      <p>
        No entries were found
      </p>
    </div>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import PieChart from '@/components/Charts/PieChart.vue';
import PeriodsSelect from '@/components/Inputs/selects/PeriodsSelect.vue';

export default {
  components: {
    Widget,
    PieChart,
    PeriodsSelect,
  },
  data: () => ({
    programmes: {
      datasets: [{
        data: [],
        backgroundColor: [],
        borderWidth: 1,
      }],
      labels: [],
    },
    loaded: false,
    filters: {
      from: null,
      to: null,
      class_schedule_id: null,
    },
  }),
  methods: {
    getData() {
      this.loaded = false;

      this.programmes.labels = [];
      this.programmes.datasets[0].data = [];

      this.request(this.$API.PROGRAMMES_PER_LEVEL, 'get', this.filters, ({ data }) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const x of data) {
          if (x.qty) {
            this.programmes.labels.push(x.name);
            this.programmes.datasets[0].data.push(parseFloat(x.qty));
          }
        }

        this.loaded = true;
      });
    },
  },
};
</script>

<style scoped>
.responsive-height {
  height: 400px;
  width: 50%;
}

.empty-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-data p {
  margin-top: 0;
}
</style>
