<template>
  <Widget
    title="Change Log"
    icon="track_changes"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <ul class="list-group list-group-flush">
      <li
        v-for="(c,i) of changes"
        :key="i"
        class="list-group-item"
      >
        <b>Version <span v-html="c.version" /></b> <br>
        <ul>
          <li
            v-for="(item,a) of c.changes"
            :key="a"
          >
            <template v-if="isString(item)">
              {{ item }}
            </template>
            <template v-else>
              <b>{{ item.title }}</b> <br>
              <ul>
                <li
                  v-for="(b,d) of item.subchanges"
                  :key="d"
                >
                  <div v-html="b" />
                </li>
              </ul>
            </template>
          </li>
        </ul>
      </li>
    </ul>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';

export default {
  components: {
    Widget,
  },
  data: () => ({
    changes: [],
  }),
  methods: {
    getData() {
      this.request(this.$API.CHANGELOG, 'get', null, (data) => {
        this.changes = data;
      });
    },
    isString(a) {
      return typeof a === 'string';
    },
  },
};
</script>
