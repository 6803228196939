<template>
  <Widget
    title="Week Absent Students"
    icon="track_changes"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <template slot="filters">
      <form-date
        v-model="date"
        label="Date..."
        class="pr-2 w-100 d-inline-block"
        :disabled-dates="{ weekdays: [1,3,4,5,6,7] }"
      />
      <md-button
        class="md-info md-sm md-block"
        @click="getData"
      >
        Filter
      </md-button>
    </template>

    <ul
      v-if="students.length"
      class="list-group list-group-flush"
    >
      <li
        v-for="(e,i) of students"
        :key="i"
        class="list-group-item"
      >
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <b>Student Number:</b> {{ e.student_number }} <br>
            <b>Name:</b> {{ e.full_name }}
          </div>
          <div>
            <router-link
              :to="{
                name: 'ProgrammeDetails',
                params: { id: e.programme_id }
              }"
              class="float-right"
            >
              <md-icon class="pointer md-success">
                double_arrow
                <md-tooltip md-direction="top">
                  Go To Programme
                </md-tooltip>
              </md-icon>
            </router-link>
          </div>
        </div>
      </li>
    </ul>

    <p
      v-else
      class="text-center"
    >
      There are no Absent Students for this Week
    </p>
  </Widget>
</template>

<script>
import moment from 'moment';
import Widget from '@/components/Widget.vue';
import { FormDate } from '@/components/Inputs';

export default {
  components: {
    Widget,
    FormDate,
  },
  data: () => ({
    students: [],
    date: moment().subtract(1, 'week').isoWeekday(1),
  }),
  methods: {
    getData() {
      this.request('absent_students/report', 'get', {
        date: this.date,
      }, ({ data }) => {
        this.students = data;
      });
    },
  },
};
</script>
