<template>
  <Widget
    title="Income Projection"
    icon="spellcheck"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <bar-chart
      v-if="loaded"
      v-bind="{
        chartData,
        options
      }"
      class="responsive-height w-100"
    />
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import BarChart from '@/components/Charts/BarChart.vue';

export default {
  components: {
    BarChart,
    Widget,
  },
  data: () => ({
    chartData: {
      datasets: [],
      labels: [],
    },
    loaded: false,
    options: {
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true,
        }],
      },
    },
  }),
  methods: {
    getData() {
      this.loaded = false;
      this.chartData = {
        datasets: [],
        labels: [],
      };

      this.request(this.$API.INCOME_PROJECTION_WIDGET, 'get', {}, ({ data }) => {
        data.forEach(({ name, qty }, index) => {
          const dataset = [0, 0, 0];
          dataset[index] = qty;

          this.chartData.labels.push(name);
          this.chartData.datasets.push({
            label: name,
            backgroundColor: '#f87979',
            data: dataset,
          });
        });

        this.loaded = true;
      });
    },
  },
};
</script>

<style scoped>
.responsive-height {
    height: 550px;
    width: 50%;
  }
</style>
