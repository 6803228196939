<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Moneyflow Details
    </template>
    <template slot="body">
      <div class="md-layout">
        <div class="md-layout-item">
          <form-date
            v-model="item.date"
            label="Moneyflow Date"
            disabled
          />
        </div>
        <div class="md-layout-item">
          <form-number
            v-model="item.total"
            label="Total"
            icon="euro"
            disabled
          />
        </div>
        <div class="md-layout-item">
          <md-button
            v-if="item.file"
            class="md-info md-round w-100"
            @click="onDownload"
          >
            <md-icon>
              get_app
            </md-icon>
            Download File
          </md-button>

          <form-text
            v-else
            value="No File Uploaded"
            label="File"
            icon="file_present"
            disabled
          />
        </div>
        <div class="md-layout-item md-size-100">
          <form-text
            v-model="item.remark"
            disabled
            label="Remark"
            icon="description"
          />
        </div>
        <hr class="w-100">

        <div class="md-layout-item md-size-100">
          <form-text
            v-model="item.deleter.name"
            label="Deleter"
            icon="face"
            disabled
          />
        </div>
        <div class="md-layout-item md-size-100">
          <form-date
            v-model="item.deleted_at"
            label="Deleted at"
            disabled
          />
        </div>
        <div class="md-layout-item md-size-100">
          <form-textarea
            :value="item.delete_remark || '-'"
            disabled
            label="Delete Remark"
          />
        </div>
      </div>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormText,
  FormNumber,
  FormTextarea,
  FormDate,
} from '@/components/Inputs';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    FormText,
    FormNumber,
    FormTextarea,
    FormDate,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    result: {},
  }),
  mounted() {
    this.result = this.item;
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    onDownload() {
      this.request(`${this.$API.MONEYFLOW}download/${this.item.moneyflow_id}`, 'download', null, ({ data }) => {
        const filename = this.item.file.path.split('/')[this.item.file.path.split('/').length - 1];
        downloadFile(data, filename);
      });
    },
  },
};
</script>
<style></style>
