<template>
  <Widget
    title="Programme x Class Time"
    icon="list"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <BarChart
      v-if="loaded"
      :chart-data="programmes"
      :options="options"
      class="responsive-height"
    />
  </Widget>
</template>

<script>
/* eslint-disable no-restricted-syntax */
import BarChart from '@/components/Charts/BarChart.vue';
import Widget from '@/components/Widget.vue';

function groupBy(arr, prop) {
  const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
  arr.forEach((obj) => map.get(obj[prop]).push(obj));
  return Array.from(map.values());
}

export default {
  components: {
    BarChart,
    Widget,
  },
  data: () => ({
    programmes: [],
    options: {
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true,
        }],
      },
    },
    loaded: false,
  }),
  methods: {
    async getData() {
      this.loaded = false;
      const periods = await this.requestAsync(this.$API.CLASS_SCHEDULES, 'get');
      const programmesByClassTime = (await this.requestAsync(this.$API.PROGRAMMES_BY_CLASS_TIME, 'get')).data;

      const emptyDataset = {
        label: null,
        data: [],
        backgroundColor: [],
        borderWidth: 0,
      };

      this.programmes = {
        datasets: [],
        labels: [],
      };
      for (const p of periods.data) {
        this.programmes.labels.push(p.period);
      }

      const groups = groupBy(programmesByClassTime, 'status');
      for (const x of groups) {
        const newSet = JSON.parse(JSON.stringify(emptyDataset));
        for (const z of x) {
          newSet.label = z.status;
          newSet.data[this.programmes.labels.indexOf(z.period)] = z.qty;
        }
        this.programmes.datasets.push(newSet);
      }
      this.loaded = true;
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
i.md-icon {
    font-size: 1.3em !important;
    vertical-align: sub;
}
</style>
