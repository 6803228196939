<template>
  <Widget
    title="Outflow By Category"
    icon="assessment"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <template slot="filters">
      <YearsSelect
        v-model="filters.year"
        class="pr-2 w-50 d-inline-block"
      />
      <MonthsSelect
        v-model="filters.month"
        class="pl-2 w-50 d-inline-block"
      />
      <md-button
        class="md-info md-sm md-block"
        @click="getData"
      >
        Filter
      </md-button>
    </template>
    <table class="table table-hover table-striped w-100">
      <thead>
        <tr>
          <th class="text-left">
            OUT {{ filtered.year }}
          </th>
          <th width="20%">
            {{ monthTitle }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="f of flow"
          :key="f.id"
        >
          <td>{{ f.category }}</td>
          <td class="text-right">
            {{ f.total | currency('€') }}
          </td>
        </tr>
        <tr>
          <td>TOTAL</td>
          <td class="text-right">
            {{ total | currency('€') }}
          </td>
        </tr>
      </tbody>
    </table>
  </Widget>
</template>

<script>
import moment from 'moment';
import Widget from '@/components/Widget.vue';
import { MonthsSelect, YearsSelect } from '@/components/Inputs/selects';

export default {
  components: {
    Widget,
    MonthsSelect,
    YearsSelect,
  },
  data: () => ({
    flow: [],
    filters: {
      year: moment().format('Y'),
      month: parseInt(moment().format('MM'), 10),
    },
    filtered: {
      year: moment().format('Y'),
      month: parseInt(moment().format('MM'), 10),
    },
    years: [],
  }),
  computed: {
    total() {
      return this.flow.reduce((a, b) => a + parseFloat(b.total), 0).toFixed(2);
    },
    monthTitle() {
      return moment(this.filtered.month, 'M').format('MMMM');
    },
  },
  methods: {
    getData() {
      this.request(this.$API.OUTFLOW_BY_CATEGORY, 'get', { ...this.filters }, ({ data }) => {
        this.flow = data;
        this.filtered = { ...this.filters };
      });
    },
  },
};
</script>

<style scoped>
td.red {
  color: red;
  font-weight: 600;
}
td.green {
  color: green;
  font-weight: 600;
}
</style>
