<template>
  <Widget
    title="Overenrolled Classrooms"
    icon="list"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <ul class="list-group list-group-flush m-0">
      <li
        v-for="(s,i) of classrooms"
        :key="i"
        class="list-group-item"
      >
        <router-link
          :to="{
            name: 'ClassroomDetails',
            params: { id: s.classroom_id },
          }"
          class="float-right"
        >
          <md-icon class="pointer md-success">
            double_arrow
            <md-tooltip md-direction="top">
              Go To Classroom Details
            </md-tooltip>
          </md-icon>
        </router-link>

        <b>{{ s.classroom_code }} - {{ s.classroom_name.name }}</b> <br>
        <small>
          Enrolled Students:
          {{ s.enrolments }}
        </small>
      </li>
    </ul>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';

export default {
  components: {
    Widget,
  },
  data: () => ({
    classrooms: [],
  }),
  methods: {
    getData() {
      this.request('classrooms_overenrolled/report', 'get', null, (data) => {
        this.classrooms = data.data;
      });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
i.md-icon {
    font-size: 1.3em !important;
    vertical-align: sub;
}
.widgetBody {
  max-height: 600px;
  overflow: auto;
}
</style>
