<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      <b>Settings</b>
    </template>
    <template slot="body">
      <form-select
        v-model="form.widget_id"
        label="Widget"
        icon="widgets"
        :options="widgets"
      />
      <form-select
        v-model="form.col_size"
        label="Column Size"
        icon="view_column"
        :options="sizes"
      />
      <form-select
        v-model="form.position"
        label="Position"
        icon="format_list_numbered"
        :options="positions"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-success"
        @click="saveSettings"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormSelect,
} from '@/components/Inputs';

export default {
  components: {
    FormSelect,
  },
  props: {
    widget: {
      type: Object,
      default: null,
    },
    maxPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        user_widget_id: null,
        col_size: null,
        position: null,
        widget_id: null,
        key: null,
      },
      sizes: [
        {
          id: 25,
          name: '25%',
        },
        {
          id: 33,
          name: '33%',
        },
        {
          id: 50,
          name: '50%',
        },
        {
          id: 66,
          name: '66%',
        },
        {
          id: 75,
          name: '75%',
        },
        {
          id: 100,
          name: '100%',
        },
      ],
      widgets: [],
      positions: [],
    };
  },
  mounted() {
    this.form = this.widget;
    const maxLoop = this.widget.user_widget_id ? this.maxPosition : this.maxPosition + 1;

    for (let i = 1; i <= maxLoop; i += 1) {
      this.positions.push({
        id: i,
        name: i,
      });
    }

    // default values
    if (!this.form.user_widget_id) {
      this.form.position = this.maxPosition + 1;
      this.form.col_size = 25;
    }

    this.getAvailableWidgets();
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveSettings() {
      this.$emit('onSave', this.form);
      this.close();
    },
    getAvailableWidgets() {
      this.request(this.$API.WIDGETS, 'get', null, ({ data }) => {
        this.widgets = data
          .map((x) => ({
            id: x.widget_id,
            name: x.name,
          }));
      });
    },
  },
};
</script>
<style></style>
