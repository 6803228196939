<template>
  <Widget
    title="Classroom Numbers"
    icon="track_changes"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <LevelsSelect
          v-model="filters.level_id"
          all-option="All"
          no-clear-btn
          @change="getData"
        />
      </div>
      <div class="md-layout-item md-size-50">
        <PeriodsSelect
          v-model="filters.period_id"
          all-option="All"
          @change="getData"
        />
      </div>
    </div>

    <table class="table table-hover table-striped w-100">
      <thead>
        <tr>
          <th>Level</th>
          <th>Period</th>
          <th>Opened Classrooms</th>
          <th>Total Capacity</th>
          <th>Enrolled</th>
          <th>On Holiday</th>
          <th>Available</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="l of report"
          :key="l"
        >
          <td>{{ l.level }}</td>
          <td>{{ l.period }}</td>
          <td>{{ l.opened }}</td>
          <td>{{ l.total_capacity }}</td>
          <td>{{ l.enrolled }}</td>
          <td>{{ l.holiday }}</td>
          <td :class="l.available > 0 ? 'text-green' : 'text-red'">
            {{ l.available }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="2">
            TOTALS
          </th>
          <td>{{ reportTotals('opened') }}</td>
          <td>{{ reportTotals('total_capacity') }}</td>
          <td>{{ reportTotals('enrolled') }}</td>
          <td>{{ reportTotals('holiday') }}</td>
          <td>{{ reportTotals('available') }}</td>
        </tr>
      </tfoot>
    </table>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import { LevelsSelect, PeriodsSelect } from '@/components/Inputs/selects';

export default {
  components: {
    Widget,
    LevelsSelect,
    PeriodsSelect,
  },
  data: () => ({
    report: [],
    filters: {
      level_id: 'all',
      period_id: 'all',
    },
  }),
  methods: {
    getData() {
      this.request('classrooms/numbers_report', 'get', this.filters, ({ data }) => {
        this.report = data;
      });
    },
    reportTotals(attr) {
      if (!this.report) return '-';

      return this.report.reduce((a, b) => a + b[attr], 0);
    },
  },
};
</script>

<style>
.text-green {
  font-weight: 600;
  color: green;
}
.text-red {
  font-weight: 600;
  color: red;
}
</style>
