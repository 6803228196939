<template>
  <Widget
    title="Package Items from Income"
    icon="track_changes"
    @onGetData="initTable"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <template slot="filters">
      <form-date
        v-model="filters.from"
        name="from"
        label="Created From..."
        class="pr-2 w-50 d-inline-block"
      />
      <form-date
        v-model="filters.to"
        name="to"
        label="Created To..."
        class="pr-2 w-50 d-inline-block"
      />
      <md-button
        class="md-info md-sm md-block"
        @click="initTable"
      >
        Filter
      </md-button>
    </template>
    <vue-table
      ref="vtable"
      :values="vTable.values"
      :headers="vTable.headers"
      :actions="vTable.actions"
      :options="{
        tableClass: 'table',
      }"
      :search="false"
      @changed="getData"
      @checkIncome="checkIncome"
      @goToDetails="goToDetails"
      @goToStudent="goToStudent"
    />
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import VueTable from '@/components/Tables/VueTable.vue';
import {
  FormDate,
} from '@/components/Inputs';

export default {
  components: {
    Widget,
    FormDate,
    VueTable,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'student_number',
          mask: 'student number',
        },
        {
          title: 'name',
        },
        {
          title: 'start_date',
          mask: 'start date',
        },
        {
          title: 'item_name',
          mask: 'item name',
        },
        {
          title: 'price',
        },
        {
          title: 'created_at',
          mask: 'created date',
          dateTimeFormat: true,
        },
      ],
      actions: [
        {
          buttonClass: 'md-primary',
          tooltip: 'Go To Income Details',
          callback: 'goToDetails',
          icon: 'double_arrow',
        },
        {
          buttonClass: 'md-success',
          tooltip: 'Check Income',
          callback: 'checkIncome',
          icon: 'done_all',
        },
        {
          buttonClass: 'md-success',
          tooltip: 'Go to Student Resume',
          callback: 'goToStudent',
          icon: 'person',
        },
      ],
      values: {},
    },
    report: [],
    filters: {},
    loading: false,
  }),
  methods: {
    initTable() {
      this.$refs.vtable.init();
    },
    goToDetails({ income_id }, isMiddleClick) {
      this.openLink({
        name: 'MoneyInflow',
        query: { id: income_id },
      }, isMiddleClick);
    },
    checkIncome({ income_id }) {
      this.request(`moneyflow/${income_id}/check`, 'put', null, () => {
        this.fireSuccess('Income Checked succesfully');
        this.getData();
      });
    },
    getData(params) {
      this.loading = true;
      this.request('packages/items/from_income', 'get', {
        ...params,
        ...this.filters,
      }, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
    goToStudent({ student_id }, isMiddleClick) {
      this.openLink({
        name: 'StudentResume',
        params: { id: student_id },
      }, isMiddleClick);
    },
  },
};
</script>
