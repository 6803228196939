<template>
  <Widget
    title="Programmes By Status"
    icon="person"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <div
      slot="filters"
      class="md-layout"
    >
      <div class="md-layout-item">
        <CoursesSelect
          v-model="filters.course_id"
        />
      </div>
      <div class="md-layout-item">
        <VisaTypesSelect
          v-model="filters.visa_type"
        />
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-sm md-success md-block"
          @click="getData"
        >
          Filter
        </md-button>

        <div class="alert alert-warning text-center">
          This Chart includes all the existent Programmes by Status
        </div>
      </div>
    </div>

    <div
      v-if="loaded && tableData.length"
      class="percountry-content flex-albatros"
    >
      <pie-chart
        :chart-data="statuses"
        :options="{}"
      />
      <md-table
        v-model="tableData"
        table-header-color="green"
        class="students-table"
      >
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
        >
          <md-table-cell md-label="Status">
            {{ item.status }}
          </md-table-cell>
          <md-table-cell md-label="Quantity">
            {{ item.qty }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>

    <p
      v-if="loaded && !tableData.length"
      class="text-center"
    >
      There is no data available with this filters.
    </p>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import PieChart from '@/components/Charts/PieChart.vue';
import {
  CoursesSelect,
  VisaTypesSelect,
} from '@/components/Inputs/selects';

export default {
  components: {
    PieChart,
    CoursesSelect,
    VisaTypesSelect,
    Widget,
  },
  data: () => ({
    statuses: null,
    tableData: null,
    loaded: false,
    filters: {
      course_id: null,
      visa_type: null,
    },
  }),
  methods: {
    getData() {
      this.loaded = false;
      this.request(this.$API.PROGRAMMES_BY_STATUS, 'get', this.filters, ({ data }) => {
        this.tableData = data;
        this.statuses = {
          datasets: [{
            data: [],
            backgroundColor: [],
            borderWidth: 1,
          }],
          labels: [],
        };

        // eslint-disable-next-line no-restricted-syntax
        for (const x of data) {
          if (x.qty) {
            this.statuses.labels.push(x.status);
            this.statuses.datasets[0].data.push(parseFloat(x.qty));
          }
        }

        this.loaded = true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.percountry-content {
  display: flex;
  .responsive-height {
    height: 400px;
    width: 50%;
  }

  .students-table {
    width: 50%;
    padding-left: 20px;
    .flag {
      width: 25px;
    }
  }

  @media(max-width: 700px) {
    flex-direction: column;
    align-items: center;

    .responsive-height {
      height: 300px;
      width: 100%;
    }

    .students-table {
      padding-left: unset;
      margin-top: 20px;
      width: 100%;
    }
  }
}
.divider {
  border-bottom: 1px solid #ccc;
  padding: 0;
  margin: 15px 0 25px 0;
}
</style>
