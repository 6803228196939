<template>
  <md-card class="widget-card">
    <md-card-header>
      <div
        class="card-settings"
        @click="openSettings"
      >
        <md-icon>
          settings
        </md-icon>
      </div>
      <div
        class="card-settings mr-1"
        @click="deleteWidget"
      >
        <md-icon>
          close
        </md-icon>
      </div>
      <div
        class="card-settings"
        @click="getData"
      >
        <md-icon>
          sync
        </md-icon>
      </div>
      <div class="card-icon">
        <md-icon>{{ icon }}</md-icon>
      </div>
      <h4
        class="title"
        v-html="title"
      />
    </md-card-header>
    <md-card-content
      :class="{ with_filters: hasFilters }"
    >
      <div
        v-if="hasFilters"
        ref="filters"
        class="filters"
      >
        <slot name="filters" />
      </div>

      <div class="widget-content">
        <slot />
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    hasFilters() {
      return !!this.$slots.filters;
    },
    contentHeight() {
      if (!this.hasFilters) return this.height;

      const fh = this.$refs;
      console.log(fh);
      return 100;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    openSettings() {
      this.$emit('onOpenSettings');
    },
    deleteWidget() {
      this.$emit('onDeleteWidget');
    },
    getData() {
      this.$emit('onGetData');
    },
  },
};
</script>
