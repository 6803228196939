<template>
  <Widget
    title="Online Users"
    icon="people"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <ul class="list-group list-group-flush">
      <li
        v-for="(u,i) of users"
        :key="i"
        class="list-group-item"
      >
        <md-icon>person</md-icon> {{ u.email }}
        <div
          class="statusTag"
          :class="{
            online: checkStatus(u) === 'Online'
          }"
        >
          {{ checkStatus(u) }}
        </div>
      </li>
    </ul>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';
import moment from 'moment';

export default {
  components: {
    Widget,
  },
  data: () => ({
    users: [],
  }),
  methods: {
    getData() {
      this.request('online_users', 'get', null, ({ data }) => {
        this.users = data;
      });
    },
    checkStatus(user) {
      if (moment(user.last_request_at).isSameOrAfter(moment().subtract(1, 'minute'))) return 'Online';
      return moment(user.last_request_at).fromNow();
    },
  },
};
</script>

<style lang="scss" scoped>
.statusTag {
  float: right;
  position: relative;
  padding-left: 25px;
  font-size: 13px;

  &:before {
    content: '';
    background: #ddd;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 4px;
    left: 0;
    border-radius: 50%;
    border: 1px solid #aaa;
  }

  &.online:before {
    background: greenyellow;
    border: 1px solid green;
  }
}
</style>
