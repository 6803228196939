<template>
  <Widget
    title="Staff biometric not in Kmpus"
    icon="people"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <ul
      v-if="users.length"
      class="list-group list-group-flush"
    >
      <li
        v-for="(user,index) of users"
        :key="index"
        class="list-group-item"
      >
        <md-icon>person</md-icon> {{ user }}
      </li>
    </ul>

    <p
      v-else
      class="text-center"
    >
      <small>There is no data to show</small>
    </p>
  </Widget>
</template>

<script>
import Widget from '@/components/Widget.vue';

export default {
  components: {
    Widget,
  },
  data: () => ({
    users: [],
  }),
  methods: {
    getData() {
      this.requestAsync('biometric/staff_not_in_kmpus', 'get')
        .then((data) => {
          this.users = data.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
