<template>
  <Widget
    title="Sales per month by Agent"
    icon="track_changes"
    @onGetData="getData"
    @onDeleteWidget="$emit('onDeleteWidget')"
    @onOpenSettings="$emit('onOpenSettings')"
  >
    <div slot="filters">
      <MonthsSelect
        v-model="filters.month"
      />
      <YearsSelect
        v-model="filters.year"
      />
      <md-button
        class="md-info md-sm md-block"
        @click="getData"
      >
        Filter
      </md-button>
      <small>Found {{ agents.length }} Agents with sales.</small>
    </div>

    <table class="table table-hover table-striped w-100">
      <thead>
        <tr>
          <th>Agent</th>
          <th>Received</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="a of agents"
          :key="a.agent_id"
          :class="{'text-bold': a.agent_id === user.user_id}"
        >
          <td>{{ a.name }}</td>
          <td>{{ a.received }}</td>
        </tr>
      </tbody>
    </table>
  </Widget>
</template>

<script>
import moment from 'moment';
import Widget from '@/components/Widget.vue';
import { MonthsSelect, YearsSelect } from '@/components/Inputs/selects';

export default {
  components: {
    Widget,
    MonthsSelect,
    YearsSelect,
  },
  data() {
    return {
      agents: [],
      filters: {
        month: moment().format('M'),
        year: moment().format('Y'),
      },
    };
  },
  methods: {
    getData() {
      this.request('sales_per_month_report', 'get', { ...this.filters }, ({ data }) => {
        this.agents = data;
      });
    },
  },
};
</script>

<style>
.text-bold {
  font-weight: 600;
}
</style>
